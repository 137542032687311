
    .user{
        &--profile{
            margin-top: 50px;
        }
        &--description{
            font-family: Lato;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            line-height: 17px;
            display: none; // until we give the option to add a description
        }
        &--info{
            padding-top: 30px;
            font-family: Lato;
            z-index: 1;
            .three-dots{
                background-color: #FFFFFF;
                width: 20px;
                display: block;
                &--col{
                    position: unset;
                    z-index: 3;
                }
                &::after{
                    content: '\2807';
                    font-size: 22px;
                    width: 5px;
                    display: block;
                    margin-left: auto;
                }
            }
            .photo-area{
                .bookmark-icon{
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: absolute;
                    display: none;
                }
                .photo {
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    // Aspect Ration 1:1 css hack
                    // Parent container height is set with padding-top
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    position: absolute;

                }
            }
            .type-label, .topic-label, .medium-label, .venue-label{
                max-width: 30%;
            }
            .type, .topic, .medium, .venue{
                p{
                    display: inline-block;
                    font-weight: 500;
                }
            }
            .bookmark, .address{
                p{
                    font-weight: 300;
                }
            }
            .bookmark {
                    p {
                        display: inline-block;    
                    }
            }
            .photo-area{
                    
                    margin-bottom: 30px;
                    // Aspect Ration 1:1 css hack
                    position: relative;
                    width: calc(100% + 30px);
                    padding-top: calc(100% + 30px);
                    // end
            }
            .user-details {
                position: relative;
                img{
                    height: 50px;
                    width: 50px;
                }
            }
            .bookmark{
                margin-bottom: 15px;
                .bookmark--image{
                    max-width: 20px;
                    input[type="checkbox"]{
                        display: none;
                    }
                }
                img{
                    width: 19px;
                    height: 30px;
                    margin-right: 10px;
                }
            }
            .address{
                margin-bottom: 15px;
                img{
                    width: 16px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
            .artist-name{
                margin-top: 15px;
                margin-bottom: 15px;
                p{
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 600;
                }
                .and{
                    font-weight: 500;
                }
            }
            .fa-ellipsis-v{
                font-size:  30px;
            }
        }
        &--username{
            font-size: 20px;
            font-weight: bold;
        }
        &--role{
            font-size: 14px;
            font-weight: 300;
        }
    }
         // Desktop specific styling
         @media (min-width: $tablet) {
            .photo-area {
                
                    height: 640px !important;
                
            }
            .user--info{
                .avatar.col-2{
                    flex: 0 0 8.33333%;
                    max-width: 8.33333%;
                }
            }
        } 
        
        // Mobile specific styling
        @media (max-width: $tablet) {

        }