.view_options_tabs{
    padding-top: 90px;
    max-width: 640px;
    margin: auto;
    .nav-link{
        background-color: white;
    }
    .nav-pills{
        justify-content: space-evenly;
    }
    .view-tab{
        background-image: url('/static/dist/img/icon_view-list_unselected.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: white;
        height: 30px;
        width: 30px;
        &.active{
            background-image: url('/static/dist/img/icon_view-list_selected.svg') ;
        }
    }
    .map-tab{
        background-image: url('/static/dist/img/icon_pin-unselected_small.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: white;
        height: 30px;
        width: 30px;
        &.active{
            background-image: url('/static/dist/img/icon_pin-selected_small.svg');
            background-size: contain;
            background-color: white;
        }
    }
    
}