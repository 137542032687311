.ajax-loader {
  // General styling
display: none;
transition: .2s all ease-in-out;
z-index: 5;
pointer-events: none;
  &--page {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: #328b63d6;
    .spinner-border {
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      position: relative;
      margin: auto;
      height: 100px;
      width: 100px;
      border: .5em solid white;
      border-right-color: transparent;
    }    
  }
  // &::after {
  //   content: "Loading...";
  //   color: white;
  //   font-weight: 500;
  // }
  // &.show { // currently using jquery .show() for toggle
  //   display: flex !important;
  // }
  
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}