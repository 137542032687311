.photo-ingestor {
// General styling
&-page{
  padding-bottom: 60px;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}