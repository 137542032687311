.navbar-bottom{
    position: fixed;
    bottom: 0;
    background-color: #131421;
    box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.3);
    min-height: 50px;
    width: 100%;
    margin-top: 60px;
    left: 0;
    z-index: 3;
    .bottom-nav-home{
        background-image: url('/static/dist/img/icon_home_unselected.svg');
        background-size: cover;
        background-position: center;
        height: 25px;
        width: 25px;
        &.active{
            background-image: url('/static/dist/img/icon_home_selected.svg');
        }
    }
    .bottom-nav-upload{
        background-image: url('/static/dist/img/icon_add-photo_unselected.svg');
        background-size: cover;
        background-position: center;
        height: 25px;
        width: 25px;
        &.active{
            background-image: url('/static/dist/img/icon_add-photo_selected.svg');
        }
    }
    .bottom-nav-bookmark{
        background-image: url('/static/dist/img/icon_bookmark_unselected.svg');
        background-size: cover;
        background-position: center;
        height: 25px;
        width: 25px;
        &.active{
            background-image: url('/static/dist/img/icon_bookmark_selected.svg');
        }
    }
    .navbar-nav{
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        max-width: 640px;
    }
    &.logged-out {
        .bottom-nav-upload, .bottom-nav-bookmark {
            opacity: .5;
            // cursor: not-allowed;
            a {
                // pointer-events: none;
            }
        }
    }
}