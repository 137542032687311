.rating-stars {
// General styling
width: 96px;
height: 18px;
position: relative;
* {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: .2s all ease-in-out;
}
&_yellow {
    background-image: url('/static/dist/img/rating/stars-yellow.png');
    z-index: 1;
    background-size: auto 99%;
    width: 70%;
}
&_white {
    background-image: url('/static/dist/img/rating/stars-white.png');

}
&_edit {

$starColour: $brand-primary;
$starHoverColour: $brand-primary;


  font-family: "Font Awesome 5 Free"; 
  margin: 50px auto;
  
  > fieldset {
    border: none;
    display: inline-block;
    
    &:not(:checked) {
      > input {
        position: absolute;
        display: none;
        clip: rect(0,0,0,0);
      }

      > label {
        float: right;
        width: 35px;
        padding: 0;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-size: 200%;
        color: $starColour;
        
        &:before {
          content: '\f005';
          
        }
        
        &:hover,
        &:hover ~ label {
          color: $starHoverColour;
          text-shadow: 0 0 3px $starHoverColour;
          &:before {
          content: '\f005';
          font-weight: 900;
          }
        }
      }
    }
    
    > input:checked {
      & ~ label {
        &:before {
          content: '\f005';
          font-weight: 900;
        }
      }    
    }    

    > label:active {
      position: relative;
      top: 2px;
    }
  }
}



// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}