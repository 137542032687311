.home {
    > .content {
        padding-top: 0;
      }
      .btn_back {
        display: none;
      }
      .sb-search {
        margin-left: 0;
        &-open {
          width: calc(100% - 50px);
        }
      }
}