/* Search icon by IcoMoon, made with http://icomoon.io/app/ */
@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot');
	src:url('../fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.woff') format('woff'),
		url('../fonts/icomoon/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.sb-search {
	position: relative;
	width: 0%;
	min-width: 60px;
	height: 60px;
  float: left; 
  margin-left: $s-m;
  	overflow: hidden;
	-webkit-transition: width 0.3s;
	-moz-transition: width 0.3s;
	transition: width 0.3s;
	-webkit-backface-visibility: hidden;
}

#sb-search-input {
    font-family: Montserrat !important;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 9px !important;
    border: none !important;
    outline: none;
    background: #fff;
    width: 90%;
    height: 50px;
    margin: 0;
    z-index: 1;
    padding: 5px 65px 10px 20px;
    font-family: inherit;
    font-size: 14px !important;
    color: #2c3e50;
}

#sb-search-input::-webkit-input-placeholder {
	color: #efb480;
}

#sb-search-input:-moz-placeholder {
	color: #efb480;
}

#sb-search-input::-moz-placeholder {
	color: #efb480;
}

#sb-search-input:-ms-input-placeholder {
	color: #efb480;
}

.sb-icon-search,
#sb-search-submit  {
	width: 60px;
	height: 60px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0;
	margin: 0;
	line-height: 60px;
	text-align: center;
	cursor: pointer;
}

#sb-search-submit {
	background: #fff; /* IE needs this */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
    filter: alpha(opacity=0); /* IE 5-7 */
    opacity: 0;
	color: transparent;
	border: none;
	outline: none;
	z-index: -1;
}

.sb-icon-search {
	color: #fff;
	background: #FF4A04;
	z-index: 90;
	font-size: 22px;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
}

/*.sb-icon-search:before {*/
	/*content: "\e000";*/
/*}*/

/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
	width: 100%;
      border: 1px solid #FF4A04;
}

.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {
	background: #FF4A04;
	color: #fff;
	z-index: 1;
}

.sb-search.sb-search-open #sb-search-submit,
.no-js .sb-search #sb-search-submit {
	z-index: 90;
}


// Top Navigation Search Bar

// Default logged out styling
.sb {
    &-search {
      border-radius: 12px;
      border-radius: 27px;
      min-width: 27px;
      height: 27px;
      form {
        margin: 0;
        input {
        width: 27px;
        height: 27px;
        font-size: 12px !important;
          &.sb-search-input {
            position: relative !important;
            padding: 0 !important;
            height: 20px !important;
          }
          &.sb-search-submit {
            width: 27px;
            height: 27px;
          }
        }
      }
    .sb-icon-search {
      background: transparent;
      color: $font-general;
      width: 27px;
      color: #7A7A7A;
      font-size: 15px;
      font-weight: 500;
      line-height: 27px !important;
      height: 27px;
      width: 27px;
       // i {
        // top: -1px;
        // position: relative;
        // font-size: 16px;
       // }
       img {
       	width: calc( 25px*0.8);
    	height: auto;
    	filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.3));
       }
    }
      &.sb-search-open {
        width: calc(100% - (#{$s-m} + 50px));
        border: 1px solid #4F9DA6;
        .sb-icon-search {
            background: transparent;
            color: $font-general;
            display: flex;
            align-items: center;
//             padding-top: 2px;
          }
      }
  }
  &-icon-search {
      background: transparent;
      color: $font-general;
  }
  }
  
  // Inline Search Bar (Datasets)
  .search-form {
      margin-bottom: 0;
      border-bottom: 0;
      background: white;
      padding: 20px 20px 10px 20px;
      .search-input {
          &.search-giant {
              input {
                  border: 1px solid $font-general;
                  border-radius: 12px;
                  font-size: 12px !important;
                  line-height: 13px;
                  padding: 6px 0 4px 10px !important;
              }
              button {
                  margin-top: -10px;
                  right: 0; 
                  height: unset; 
              .fa {
                  font-size: 15px;
                  color: $font-general;
              }
          }
          }
  
      }
      select {
          border: 1px solid $font-general;
          border-radius: 12.5px;
          padding: 3px;
          height: 25px;
          line-height: 25px;
      }
  }
  
  // Logged In Styling
  .logged-in {
    .search-form {
      h2 {
        padding-bottom: 20px;
        border-bottom: 1px dotted $font-general;
        }
      }
  }
  
  // Navbar search place holder styling
  #sb-search-input::-moz-placeholder {
      font-size: 12px;
      color: $font-general !important;
      position: relative;
      opacity: 0;
      transition: .2s all ease-in-out;
    }
  #sb-search-input::-webkit-input-placeholder {
      font-size: 12px;
      color: $font-general !important;
      position: relative;
      opacity: 0;
      transition: .2s all ease-in-out;
    }
  #sb-search-input:-moz-placeholder {
      font-size: 12px;
      color: $font-general !important;
      position: relative;
      opacity: 0;
      transition: .2s all ease-in-out;
    }
  #sb-search-input:-ms-input-placeholder {
      font-size: 12px;
      color: $font-general !important;
      position: relative;
      opacity: 0;
      transition: .2s all ease-in-out;
    }
  
    // Search bar opened
    .sb-search-open {
      #sb-search-input::-moz-placeholder {
          opacity: 1;
        }
      #sb-search-input::-webkit-input-placeholder {
          opacity: 1;
        }
      #sb-search-input:-moz-placeholder {
          opacity: 1;
        }
      #sb-search-input:-ms-input-placeholder {
          opacity: 1;
        }
    }
  
    // Desktop specific styling
  @media (min-width: $tablet) {
  	.sb-search {
  		position: absolute;
  		z-index: 1;
  	}
  }
  
    // Mobile specific styling
    @media (max-width: $tablet) {
     
    }

    