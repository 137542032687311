.login-sign-up {
  // General styling
  background: url('/static/dist/img/bg_login-signup.jpg') center/cover;
  padding-left: 20px;
  padding-right: 20px;
  min-height: calc(100vh - (#{$footer-cta-height}));
  padding-top: 100px;
  padding-bottom: 60px;
.card {
  padding: $s-m;
  background: white;
  .btn {
    margin: auto;
    display: flex;
    margin-top: 40px;
    color: white;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
  .login {
    padding-left: 0;
    padding-right: 0;
  }
  form {
    background: white;
    .tandcs{
      font-size: 14px;
      font-style: italic;
      font-family: Lato;
      text-align: center;
      color: #4A4A4A;
      margin-top: 40px;
      p{
        font-style: italic;
        font-family: Lato;
      }
      a{
        text-decoration: underline;
        font-family: Lato;
      }
    }
    input {
      margin-top: 5px !important;
      font-size: 12px;
      &.checkbox {
        width: 30px;
        position: relative;
        top: 1.9px;
      }
    }
    p {
      font-size: 14px;
    }
    label{
        margin-top: $s-s;
      &.checkbox {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        line-height: 28px;
      }
    }
    .checkbox {
      display: inline-block;
      width: initial;
    }
  }
}
.nav-tabs {
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $s-m;
  li a {
    width: 115px;
    text-align: center;
    padding: 5px 15px;
    display: block;
    border: $border;
    transition: .2s all ease-in-out;
    text-decoration: none;
    font-weight: 500;
    text-transform: capitalize;
    background: white;
    &.active {
      background: $font-general;
      color: white;
    }
    &.login {
      border-radius: 5px 0 0 5px;
    }
    &.sign-up {
      border-radius: 0 5px 5px 0;
    }
  }
}
// Desktop specific styling
@media (min-width: $tablet) {
   min-height: calc(100vh - (#{$footer-cta-height});
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}