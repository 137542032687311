.header--navigation {

  // TEMP FIX FOR JINJA BUG - REPEATING NAV ON LANDING PAGE
  + #{&} {
    display: none;
  }
  //   General styling
    position: fixed;
    width: 100%;
    top: -1px;
    z-index: 4;
    background: white;
    transition: all .2s ease-in-out;
//     border-bottom: 1px solid lightgrey;
    &.dropshadow {
      box-shadow: $box-shadow;
    }
    .navbar {
      height: $navigation-height-desktop;
      padding: $s-t;
      &-collapse {
        text-align: center;
        background: rgba(79,157,166,0.9);;
        z-index: 1;
        padding: 0 25px;
        padding-top: 50px;
        margin: 0 -25px;
        top: -$navigation-height-desktop;
        position: relative;
        .nav-item {
          text-align: center;
        }
        .nav-link {
          font-size: 16px;
          font-weight: 300;
          color: white;
          text-transform: capitalize;
          text-align: center;
          &.signup {
            color: $brand-primary;
            font-weight: 700;
            text-shadow: inset 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
            margin-top: $s-s;
          }
        }
        &.fill {
          box-shadow: $box-shadow;
        }
      }
      &-brand {
        padding: 0;
        position: relative;
        z-index: 2;
        height: 100%;
        img {
          width: 100px;
        }
      }
      &-toggler {
        position: absolute;
        right: 0;
        z-index: 2;
        width: calc(30px * 0.8);
        padding: 0;
        outline: none;
        border: 0;
        &-line {
          width: 100%;
          height: 2px;
          background: #2D3036;
          display: block;
          margin: 7px auto;
          transition: .2s all ease-in-out;
          border-radius: 2px;
        }
        &[aria-expanded="true"]{
         > span {
           &:nth-of-type(1) {
            transform: rotate(45deg);
            top: 4px;
            position: relative;
           }
           &:nth-of-type(2) {
            display: none;
           }
           &:nth-of-type(3) {
            transform: rotate(135deg);
            bottom: 5px;
            position: relative;
           }
         } 
        }
      }
      &-nav {
        margin-top: $navigation-height-desktop;
        padding-bottom: $s-m;
      }
      &--next {
        position: absolute;
        right: 0;
        color: $brand-primary !important;
        font-weight: 500;
        pointer-events: none;
        opacity: .5;
        &.show {
          pointer-events: all;
          opacity: 1;
        }
      }
    } 
    
    // Desktop specific styling
    @media (min-width: $tablet) {
      .container{
        padding-right: 0;
      }
    } 
    
    // Mobile specific styling
    @media (max-width: $tablet) {
      .navbar {
        padding: 0;
         &-collapse {
         z-index: 1;
         padding: 0;
         width: 50%;
         top: 0;
         right: -50%;
         max-width: 320px;
         left: unset;
         position: fixed;
         box-shadow: $box-shadow;
         height: 100% !important;
         display: block !important;
         transition: .2s right ease-in-out;
         text-align: right;
         margin: 0;
         &.reveal {
          right: 0px;
         }
          li.nav-item {
            &:nth-of-type(3) {
              border-bottom: 1px solid white;
              padding-bottom: $s-s;
            }
            .login {
              padding-top: $s-s;
              margin-top: $s-m;
            }
             &:last-of-type {
              margin-top: $s-s;
            }
            .nav-link {
              padding: 15px;
            }
          }
        }
      }
    }
  }