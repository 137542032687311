.background-filler {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .8;
}
.show {
    display: block !important;
  }
.hide {
    display: none !important;
  }
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.no-margin {
    margin: 0 !important;
}
.no-padding {
    margin: 0 !important;
}
.inactivelink {
    pointer-events: none;
    cursor: default;
    color: rgba(255, 255, 255, 0.5) !important;
 }
.click-layer {
    width: 100% !important;
    height: 100% !important;
    display: block;
    position: absolute !important;
    z-index: 3 !important;
  }

  .readonly {
      opacity: 0.2;
      pointer-events: none;
  }
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {

  }