.photo-ingestor {
  .frame {
    overflow: hidden;
  }

  .moving-slate {
    width: calc(200% + 60px);
    transition: margin .2s ease-in-out;

    .half-slate {
      max-width: calc(100% - 30px);

      select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%), linear-gradient(to right, white, white);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    }
    }

    &.shift-left {
      margin-left: calc(-100% - 45px);
    }

    &.shift-right {
      margin-left: 0;
    }
  }

  // local variables
  $wradio: 20px;

  // General styling
  &--choose-photo {
    margin-bottom: $s-m;

    &_preview {
      $x: 145px;
      background-color: rgba(19, 20, 33, 1);
      width: $x;
      height: $x;
      border-radius: 50%;
      display: block;
      margin: $s-m auto !important;
      box-shadow: $box-shadow;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: .2s all ease-in-out;

      img {
        width: 70px;
      }

      &.selected {
        //         background-color: transparent;
        border-radius: 3px;

        img {
          display: none;
        }
      }
    }

    .bootstrap-filestyle {
      input[type="text"] {
        display: none;
      }

      .input-group-btn {
        margin: auto;

        label.btn {
          margin: auto;
          margin-top: -188px;
          padding-top: 188px;
        }

        .buttonText {
          color: white;
          margin: 0;
          margin: auto;
          background: rgba(19, 20, 33, 1);
          border-radius: 17.5px;
          cursor: pointer;
          padding: 3px 10px;
          box-shadow: $box-shadow;

          &:focus {
            box-shadow: 0;
          }
        }

        &:before {
          content: "";
          display: block;
        }
      }

    }

  }

  &--location-edit {

    input,
    label:not(:first-of-type) {
      display: inline-block;
    }

    .address {
      //       width: calc(100% - (#{$wradio} + 5px)) !important;
    }

    input[type="radio"] {
      width: $wradio;
    }

    .divider {
      margin-left: $wradio;
      margin-bottom: 10px;
    }
  }

  &--date-edit {
    label {
      margin-top: 10px;
    }
  }

  &--caption-edit {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .caption {
     h2 {
      margin: $s-s 0 0;
      font-weight: 700;
      font-size: 20px;
    }
    .image-wrapper {
      padding-top: calc(100% + 30px);
      width: calc(100% + 30px);
      position: relative;
    }
    .btn_primary {
      margin: $s-s auto;
      display: block;
      background: #4FA683;
      width: 200px;
      height: 50px;
      display: flex;
      align-items: center;
      flex-flow: column;
      border-radius: 25px;
      color: white !important;
    }
     textarea {
      border: 1px solid #9B9B9B;
      border-radius: 14.5px
    }
  }


  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {}
}