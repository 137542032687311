.card {
  // General styling
background: rgba(255, 255, 255, 0.88);
box-shadow: $box-shadow;
border-radius: 5px !important;
border: 0 !important;
position: relative;
width: 100%;
max-width: 350px;
margin: auto;
&-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px !important;
  .gaussian-blur {
    filter: blur(20px);
    height: inherit;
    width: inherit;
    background: url('/static/dist/img/hero_one.jpg') center;
    top: 0;
    position: absolute;
    transform: scale(1.1);
  }
  .white-bg {
    height: inherit;
    width: inherit;
    transform: scale(1.2);
    background-color: rgba(255,255,255,0.7);
  }
}
  &-header {
    background: $background-colour;
    position: relative;
    padding: 10px 20px 10px;
    border-radius: 5px 5px 0 0 !important;
     ~ .card-body {
       padding-top: 10px;
//        padding-bottom: 0;
     }
  }
  h4 {
    margin-bottom: 0;
  }
    &-title {
        margin-bottom: 0;
            width: 235px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    + span {
      white-space: nowrap;
      width: 55px;
    }
        &-wrapper {
          width: 100%;
          * {
            display: inline-block;
            line-height: 25px;
          }
        }
    }
    &-subtitle {
    }
    &-body {
      padding: 20px;
      color: $font-general;
      position: relative;
      hr {
        border-top: 2px solid $font-general;
        margin-top: 5px;
        opacity: .5;
      }
    }
    &-details {
      text-align: left;
      &-inner-wrapper {
        display: block;
      }
      > .row {
        > div[class*="col"]{
          &:nth-of-type(1){
            padding: 0 5px 0 0;
          }
          &:nth-of-type(2){
            padding: 0 0 0 10px;
          }
        }
      }
      &-label {
        font-size: 10px;
        display: inline-block;
        text-transform: uppercase;
        width: 40px;
      }
      &-info{
        margin-left: 2px;
        text-transform: capitalize;
        font-size: 16px;
      }
      &-description {
        text-align: center;
        padding: $s-s;
      }
    }
    &-footer {

    }
    p {
      margin-bottom: 0;
    }
    h3 {
      font-size: 20px;
    }
    
    // Desktop specific styling
    @media (min-width: $tablet) {
    }
    
      // Mobile specific styling
      @media (max-width: $tablet) {
        &--title {
        }
        &-header {
        }
      }
    }
