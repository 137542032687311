.security-notice {
// General styling
background: $brand-tertiary;
color: white;
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}