i {
    &.meal-type--icon {
       @include circle-frame(20px, 1px, $brand-tertiary);
       font-style: normal;
       text-align: center;
       margin: 0;
       margin-top: 2px;
       display: inline-block;
       background: white;
       &::before {
            font-size: 10px;
            display: block;
            position: relative;
            font-weight: 400;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            height: 100%;
       }
       &.vegetarian {
           &::before {
               content: "V";
           }
       }
       &.vegan {
           &::before {
               content: "VE";
           }
       }
       &.meat {
           &::before {
               content: "M";
           }
       }
    }
    &.meal-name--icon {
        @include circle-frame(45px, 0px, transparent);
        font-size: 25px;
        text-align: center;
        font-style: normal;
        padding-top: 3px;
        color: white;
    }
// General styling

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}