// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6, p, span, label, input, ul {
  @include no-margin-padding;
}
// Force the mobile max-width
.container {
  max-width: $content-grid;
}

body {
  color: $font-general;
  font: normal 16px / 1.5 $font-family-primary;
  font-family: $font-family-primary;
  font-weight: 400;
  padding-top: $navigation-height-desktop;
  margin-bottom: 80px;
  > .content {
    padding-top: calc(#{$navigation-height-desktop} - 2px);
  }
}

/**
* Make all elements from the DOM inherit from the parent box-sizing
* Since `*` has a specificity of 0, it does not override the `html` value
* making all elements inheriting from the root box-sizing value
* See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*/
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: $background-colour;
  // background: #2d3036;
  // height: 100vh;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-primary;
  text-decoration: none;

  @include on-event {
    color: $font-general;
    text-decoration: underline;
  }
}

hr {
  border-top: 2px solid;
  opacity: .5;
  &.dashed {
    background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 11px 2px;
    background-repeat: repeat-x;
    border-top: unset !important;
    border: unset;
    height: 2px;
    opacity: .3 !important;
    margin-bottom: 10px;
  }
  &.light {
    border-color: #B2BEC3 !important;
  }
  &.requests {
    margin-bottom: 20px;
    margin-top: 20px !important;
  }
}

a {
  cursor: pointer;
}

.module {
  @include module;
  > .container {
    padding: 0;
  }
}

.component {
  margin: $s-s 0;
}

.divider {
  display: block;
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
}

.notice {
  font-weight: 500;
  font-size: 16px;
  padding: 30px;
}

  // Desktop specific styling
  @media (min-width: $tablet) {
  }
  
  // Mobile specific styling
  @media (max-width: $tablet) {
    .daterangepicker {
      display: none !important;
    }
  }
  