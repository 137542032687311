.caption{
    font-family: Lato;
    padding-bottom: 30px;
    &--heading{
        font-weight: 600;
        font-size: 20px;
        margin: 30px 0px;
    }
    &--image{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 300px;
    }
    &--input{
        border: 1px solid #4F9DA6;
        border-radius: 14.5px;
        width: 315px;
        font-size: 14px;
        margin: 30px 0px;
        padding: 5px;
        padding-left: 20px;
    }
}