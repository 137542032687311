.contact {
// General styling
// min-height: calc(100vh - (#{$footer-height} + #{$navigation-height-desktop}) + 2px);
//     width: calc(100% + 40px);
//     margin-left: -20px;
    &-content{
      margin-bottom: 120px;
      
      input, textarea{
        font-size: 14px;
        font-family: Lato;
      }
      textarea{
        border-bottom: 1px solid #5A6061;
      }
      .btn{
        color: white;
        font-family: Lato;
      }
      .hero-contact{
        background-image: url('/static/dist/img/banner_contact-us.jpg');
        background-position: center;
        background-size: cover;
        background-color: #F5A623;
        height: 234px;
      }
      .question-type {
        label {
          margin-right: 0;
          border: 1px $font-general_disabled solid;
          color: $font-general_disabled;
          width: 50%;
          &.project {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-right: -2.5px;
            &:hover {
              // @include active (white,#7DB345);
            }
          }
          &.app {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -2.5px;
            margin-top: 0 !important;
            &:hover {
              // @include active (white,#E65062);
            }
          }
        }
        input {
          &[type="radio"]{
            @include dissapear();
          }
        }
        #app:checked ~ label.app  {
          @include active ($font-general,transparent);
        }
        #project:checked ~ label.project  {
          @include active ($font-general,transparent);
        }
      }
      form {
        width: calc(100% + 40px);
        margin-left: -20px;
        padding: $s-m;
        position: relative;
        bottom: 0;
      }
      .form {
        box-shadow: $box-shadow;
      }
      h1 {
      }
      input {
        background: transparent;
        padding-left: 0;
        border-radius: 0 !important;
        &::placeholder, &:focus {
          background: transparent;
          color: $font-general !important;
          font-size: 14px;
          font-weight: 300;
        }
      }
      textarea {
        background: transparent;
        padding-left: 0;
        &::placeholder, &:focus {
          background: transparent;
          color: $font-general !important;
          font-size: 14px;
          font-weight: 300;
        }
      }
      textarea, input {
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      border-radius: 0 !important;
      line-height: 31px;
    }
      label {
        text-align: left;
      }
      button {
        color: white;
      }
      .contact-details {
        padding-top: $s-m;
        h2 {
          font-size: 20px;
        }
        p {
          margin-bottom: $s-s;
        }
        i {
          margin-right: $s-t;
          margin-bottom: 2px;;
          font-size: 30px;
          color: $brand-primary;
        }
        a {
          line-height: 29px;
          font-size: 14px;
        }
        hr {
          width: 100%;
          margin: $s-m 0;
        }
      }

    }

// Desktop specific styling
@media (min-width: $tablet) {

}

  // Mobile specific styling
  @media (max-width: $tablet) {
min-height: calc(100vh - (#{$footer-height} + #{$navigation-height-desktop}) + 13px);
form {
  width: calc(100% + 35px);
}
  }
}