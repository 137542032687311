.carousel {
// General styling
width: 100%;
&-item {
  max-width: 760px;
  h3 {
    font-size: 20px;
    width: 235px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    + span {
      width: 55px;
      white-space: nowrap;
    }
  }
  hr {
    color: white;
  }
  padding: 20px;
  .meal-type--icon {
    float: right;
  }
}
&-indicators {
  position: relative;
  padding: 20px;
  z-index: 1;
  li {
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    border-radius: 10px;
    background: white;
    border: 0;
    transition: .2s all ease-in-out;
    &.active {
      background: $brand-primary;
    }
  }
}
&-control {
  &-prev {
    left: -15px;
    height: 300px;
    &-icon {
      margin-right: 10px;
    }
  }
  &-next {
    right: -15px;
    height: 300px;
    &-icon {
      margin-left: 10px;
    }
  }
}

// Desktop specific styling
@media (min-width: $tablet) {
  .card {
    max-width: 60%;
    margin: auto;
  }
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}