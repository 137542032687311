.search--images{
    padding-bottom: 60px;
    .painting{
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 15px;
    position: relative;
    width: calc(100% + -30px);
    padding-top: calc(100% - 30px);
        &-name{
            position: absolute;
            bottom: 0;
            background-color: rgba(19,20,33,0.49);
//             height: 23px;
            box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.3);
            color: #B2BEC3;
            min-width: 100%;
            font-size: 14px;
            p{
                padding-left: 15px;
            }
        }
    }
        // Desktop specific styling
        @media (min-width: $tablet) {

    
        }
            
        // Mobile specific styling
        @media (max-width: $tablet) {
            .painting{
//                 max-width: 145px;
            }
        }
}