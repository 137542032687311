.about-us {
  // General styling
  font-family: Lato;
  color: #4A4A4A;
  &--hero{
    background-image: url('/static/dist/img/banner_about-us.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 234px;
  }
  h3{
    font-size: 20px;
    font-weight: 600;
  }
  p{
    font-size: 14px;
    font-weight: 300;
  }
  .contact-details {
    padding-top: $s-m;
    h2 {
      font-size: 20px;
    }
    p {
      margin-bottom: $s-s;
    }
    i {
      margin-right: $s-t;
      margin-bottom: 2px;;
      font-size: 30px;
      color: $brand-primary;
    }
    a {
      line-height: 29px;
      font-size: 14px;
    }
    hr {
      width: 100%;
      margin: $s-m 0;
    }
  }
  .row:not(.contact-details){
    box-shadow: $box-shadow;
  }

  // Desktop specific styling
  @media (min-width: $tablet) {}
  // Mobile specific styling
  @media (max-width: $tablet) {

  }
}