.home_hero-content {
// General styling
h2 {
    margin: 60px 0;
    width: 100%;
    text-align: center;
    font-style: oblique;
    text-shadow: 1px 2px 4px rgba(0,0,0,1);
    font-size: 20px;
    font-weight: 400;
}
.video {
    &-wrapper {
    }
    &-container {
        height: 100vh;
        max-height: 700px;
        overflow: hidden;
        video {
            object-fit: cover;
        }
    }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}