/**
 * Basic typography style for copy text
 */

label{
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}
.fa, .fas, .far, .fab  {
  font-family: 'Fontawesome';
  margin-left: 5px;
}
.info {
  display: inline-block !important;
}
h1 {
  font: $h1;
}
h2 {
  font: $h2;
}
h3 {
  font: $h3;
}
h4 {
  font: $h4;
  text-transform: uppercase;
}
h5 {
  font: $h5;
  text-transform: uppercase;
}
p {
  font: $p;
}
span {
  font: $span;
}
hr.dotted {
  border-top: none;
  height: 4px;
  overflow: hidden;
  position: relative;
  &::after{
    color: inherit;
    content: ".......................................................................";
    letter-spacing: 20px;
    font-size: 30px;
    display: block;
    line-height: 0px;
    position: relative;
    top: -7px;
  }
}

a {
  color: $font-general;
}

.fas, .fa {
  font-style: normal;
}
@media all and (display-mode: standalone) {
  body {
    background-color: yellow;
  }
}
