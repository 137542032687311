.user--profile{
    .three-dots{
        background-color: #FFFFFF;
        &--col{
            position: unset;
        }
        &::after{
            content: '\2807';
            font-size: 22px;
        }
    }
    &--info{
        max-width: 314px;
        margin: auto;
    }
    &--options{
        .dropdown{
            position: unset;
        }
        .dropdown-menu{
            background-color: rgba(79,157,166,0.8);
            box-shadow: 0 2px 9px 3px rgba(0,0,0,0.2);
            border-radius: 5px 0 0 5px;
            width: 209px;
            height: 196px;
            
        }
        .dropdown-menu-list{
            flex-direction: column;
            height: 100%;
        }
        .dropdown-item{
            color: #ffffff;
            font-size: 16px;
            text-align: center;
            font-family: Montserrat;
        }
    }
    &--image{
        border-radius: 5px;
        min-width: 140px;
        height: 140px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        margin: 10px;
        padding: 0;
        max-width: 200px;
    }
    .user--description {
        margin-top: 30px;
    }
    .title--font{
        font-weight: 600;
        font-size: 24px;
    }

        // Desktop specific styling
    @media (min-width: $tablet) {
        &--options{
            .dropdown-menu {
//                 left: -210px !important;
            }
        }
    } 
    
    // Mobile specific styling
    @media (max-width: $tablet) {
    }
}