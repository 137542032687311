button {
    &:hover, &:focus {
        outline: none;
    }
}
.trigger{
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}

.btn {
    transition: .2s all ease-in-out;
    font-weight: 500;
    height: 30px;
    min-width: 125px;
    line-height: 1.1;
    font-size: 16px;
    border: 1px solid transparent;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }
// General styling
&_primary {
    background-color: $brand-primary;
    @include border (1px, $brand-primary, 15px);
}
&_secondary {
    border: 1px solid $font-general;
}
&_light {
    background: white;
    @include border (0px, $font-general, 15px);
    box-shadow: $box-shadow;
}
&_large {
    height: 50px;
}
&_clear {
    background: transparent;
    position: relative;
    &::after {
        content: "";
        display: block;
        border-bottom: 1px solid white;
        width: calc(100% - 80px);
        margin: auto;
        position: absolute;
        bottom: 0;
    }
}
&_dark {
    background-color: $brand-tertiary;
    color: white;
    @include border (1px, $font-general, 15px);
}
&_success {
    color: white !important;
    background: $success;
    border-color: $success;
}
&_failure {
    color: white !important;
    background: $failure;
    border-color: $failure;
}
&_inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    + label {
        font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: black;
        display: inline-block;
        cursor: pointer; /* "hand" cursor */
    }
    &:focus + label,
    + label:hover {
        background-color: red;
    }
}
&_back {
    z-index: 1;
    position: absolute;
    img {
        height: 22px;
    }
}

.spinner-border {
    display: none !important;
}
&.show {
    display: flex !important;
    .spinner-border {
        display: inline-block !important;
        margin-right: 5px !important;
        width: 25px;
        height: 25px;
    }
    .btn_text {
        display: none;
    }
}
&.disabled {
    pointer-events: none;
    opacity: 0.5;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}