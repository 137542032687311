.login {
// General styling
// padding-top: 0;
margin-bottom: 0;
// height: 100vh;

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}