// -----------------------------------------------------------------------------
// This file contains all vendor-overrides
// -----------------------------------------------------------------------------
.dropdown-item {
    &:hover {
        background: unset;
        background: transparent;
    }
}

.bootstrap-filestyle {
// &.input-group {
//     position: absolute;
//     top: 0;
//     height: 85px;
//     left: 0;
// }
//  input {
//     display: flex;
//     width: 100% !important;
//     background-color: transparent !important;
//     border: 0 !important;
//     order: 2;
//     padding-left: 0;
//     padding-right: 0;
//     margin-top: 0 !important;
//     font-size: 10px !important;
//     text-align: center;
//  }
//  > span {
//      display:flex;
//      order: 1;
//      border: 0;
//      background-color: transparent;
//      border-radius: 4px;
//      margin: auto;
//      height: 100%;
//      width: 80px;
//      label {
//          border: 0;
//          color: $font-general;
//          padding-top: 0;
//          padding-bottom: 0;
//          min-width: unset;
//          line-height: unset;
//          font-size: unset;
//          margin: 0;
//          width: 100%;
//          border-radius: 42.5px;
//          top: 0;
//          margin-left: -1px;
//          position: absolute;
//          width: 85px;
//          height: 85px;
//          background: transparent;
//          &:hover {
//              background-color: rgba(0, 0, 0, 0.50) !important;
//          }
//          &:active {
//             border-radius: 42.5px;
//          }
//          span {
//             font-weight: 500 !important;
//             font-size: 10px;
//          }
//         &:hover {
//         background-color: transparent;
//         color: $font-general;
//         }
//         .buttonText {
//             // display: none;
//         }
//         &::after {
//             content: "\f030";
//             font-family: 'Font Awesome 5 Free';
//             font-weight: 900;
//             font-size: 30px;
//             color: white;
//             opacity: .7;
//             filter: drop-shadow(1px 1px 5px rgba($color: #000000, $alpha: 1.0))
//         }
//      }
//  }
}