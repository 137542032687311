.glossary--content{
  [class*='col-'] {
    padding: 0 $s-s;
  }
  h1,h2,h3 {
    margin-top: $s-s;
    font-weight: 700;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    margin-bottom: $s-s;
    font-size: 14px;
    font-weight: 300;
  }
  &_types{
    background: rgba(255,89,89,0.5);
  }
  &_topics{
    background: rgba(244,172,99,0.5);
  }
  &_medium{
    background: rgba(79,166,131,0.5);
  }
  &_venue{
    background: rgba(46,112,172,0.5);
  }
  // Desktop specific styling
  @media (min-width: $tablet) {}
  // Mobile specific styling
  @media (max-width: $tablet) {

  }
}