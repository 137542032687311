.accordion {
// General styling
width: 100%;
.card {
    border: 1px solid #4A4A4A;
    border-radius: 3px !important;
    margin: 15px 0;
    background: #E5E5E5;
    &-header {
        background: #E5E5E5;
        padding: 0;
        button, a {
            color: $font-general;
            width: 100%;
            text-align: left;
            z-index: 2;
            position: relative;        
            padding: 5px 10px ;
            &[aria-expanded="true"]{
                ~ i.fas {
                    &::before {
                        content: "\f068";
                    }
                }
            }
        }
        .fas {
            font-size: 30px;
            color: $font-general;
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 5px;
            z-index: 1;
        }
    }
    &-body {
        padding: 20px;
        .keywords--list {
        margin-bottom: 0;
            li {
                background: white !important;
                box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
                padding: 2px 10px;
                margin-right: 10px;
                &:not(:first-of-type) {
                   margin: 0 10px;
                 }
            }
        }
        h3 {
            padding: 5px 10px;
            margin-bottom: 0;
            color: $font-general;
            background: white;
            font-size: 14px;
        }
        .card {
            background: white;
            margin: 0;
            &-body {
                padding: 0;
                table {
                    width: 100%;
                    margin-bottom: 10px;
                    font-size: 14px;
                    tbody {
                        tr {
                            border-bottom: 1px solid #DDDDDD;
                            padding: 10px 10px 0;
                            &:last-of-type {
                                border-bottom: 0;
                            }
                            td {
                                padding-top: 10px;
                                vertical-align: baseline;                            
                                &:first-of-type {
                                    font-weight: 400;
                                    text-transform: uppercase;
                                    padding: 2px 10px;
                                    font-size: 10px;
                                }
                                &:nth-of-type(even) {
                                    font-weight: 500;
                                }
                                a {
                                    font-weight: 500;
                                    &:first-of-type {
                                        margin-right: 50px;
                                    }
                                }
                                span {
                                    &:first-of-type {
                                        margin-left: 50px;
                                        font-weight: 400;
                                        text-transform: uppercase;
                                        span {
                                            font-weight: 500;
                                            margin-left: 5px;
                                            font-size: 14px;
                                        }
                                    }
                                }
                                ul {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            &-footer {
                display: none;
            }
        }
    }
    &.keywords {
        .card {
            &-body {
                padding: 20px 10px;
            }
        }
    }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}