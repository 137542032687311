form, .form_view {
  width: 100%;

  // General styling 
  * {
    &:focus {
      box-shadow: none !important;
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: $s-m;
    span {
      font-size: 16px;
      line-height: 1.5;
      display: block;
    }
    .form-control {
      &::placeholder {
        color: $font-general;
        opacity: .5;
      }
    }
  }
  h1 {
    margin: $s-s 0;
  }
  h3 {
    font-size: 21px;
  }

  input:not([type="radio"]):not([type="checkbox"]) {
    width: 100%;
    outline: none;
    @include form(32px, 16px);
    // margin-top: $s-s;
    &::placeholder {
      text-transform: capitalize;
    }
    // Hide the default date picker arrow
    &::-webkit-calendar-picker-indicator, 
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
    }
    // Hide the default date picker prompt
    &[type="date"]::-webkit-input-placeholder{ 
      visibility: hidden !important;
    }
  }
  
  // Text area styling
  textarea {
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    padding: 5px;
    display: block;
    &::placeholder {
      font-size: 16px !important;
      color: #9B9B9B;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  
  // Label styling
  label {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 200;
    margin-bottom: 5px;
    &:not(:first-of-type) {
      margin-top: 10px !important;
    }
  }
  


  select {
    display: block;
    width: 100% !important;
  }
  
  // Select2 plugin styling
  .select2 {
    &-container {
      // @include form(30px, 16px);
    }
    &-search__field {
      border: none !important;
    }
    &-selection {
      line-height: 0 !important;
      &-dropdown {
        //
      }
      &__placeholder {
        //
      }
      &__arrow {
        display: flex !important;
        align-items: center;
        top: -3px !important;
        right: 12px !important;
        b {
          display: none;
        }
        &::before {
           border: 0;
            content: "\f078";
            font-family: "Font Awesome 5 Free","FontAwesome";
            font-size: 15px;
            vertical-align: 0px;
            position: relative;
            width: 15px;
            height: 15px;
            margin: 0;
            color: #B2BEC3;
            font-weight: 900;
        }
      }
      &__rendered {
        line-height: 25px !important;
      }
    }
  }
  
  // Desktop specific styling
  @media (min-width: $tablet) {
  }
  
    // Mobile specific styling
    @media (max-width: $tablet) {

    }
  }

    // Validation (for some reason not working when nested in 'form' sass above
    .was-validated {
      .help-block {
        display: none !important;
      }
    }