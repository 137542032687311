.alert{
    background-color:rgba(255,89,89,0.9);
    color: #FFFFFF;
    position: absolute;
    right: 0;
    left: 2px;
    max-width: 640px;
    width: 90%;
    z-index: 3;
    top: 150px;
    margin: auto;
    opacity: 0.95;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
    margin-bottom: 15px;
    font-family: Lato;
    text-align: center;
    min-height: 257px;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
      font-family: Lato;
      font-size: 16px;
    }
    .alert-header{
      font-family: Rajdhani !important;
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 15px;
    }
    &.direct-msg{
      background-color: rgba(79,166,131,0.9)
    }
    &.success, &.reported{
      background-color: rgba(79,166,131,0.9)
    }

    &.danger, &.danger-login{
      background-color: rgba(255,89,89,0.9);
      .login-link{
        font-size: 16px;
        text-decoration: underline;
        color: #FFFFFF;
      }
      .signup-link{
        width: 120px;
        border-radius: 14px;
        background-color: #FFFFFF;
        color: #26154B;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
      }
    }

    .close{
      padding: 0px 15px !important;
      span{
        font-size: 45px !important;
      }
      &:hover {
        color: white;
      }
    }
    // Desktop specific styling
    @media (min-width: $tablet) {
    
    }
        
    // Mobile specific styling
    @media (max-width: $tablet) {
    }
  }